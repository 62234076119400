import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/analytics'
import firebaseConfig from './firebase.config'

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const storage = firebase.storage()

const database = firebase.firestore()

export { storage, database }
