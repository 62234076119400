import { Container, styled } from '@material-ui/core'

export const Snap = styled(Container)({
  scrollSnapType: 'y proximity',
  height: '100vh',
  overflowY: 'scroll',
  overflowX: 'hidden',
  '@media(max-width:700px)': {
    scrollSnapType: 'none',
    height: '100%'
  }
})
