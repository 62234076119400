import React from 'react'
import { Button, Container, useTheme } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { storage } from '../../firebase'
import { setNavBar } from '../../Hooks/SetNavbar'
import { Section } from '../common/Section'
import { Typography } from '../common/Animation'
import { motion, Variants } from 'framer-motion'

export const Inicio: React.FC = () => {
  const { ref } = setNavBar(0)

  const handleDownload = async () => {
    const downloadUrl: string = await storage
      .ref('/CV_Gustavo_PT.pdf')
      .getDownloadURL()
    window.open(downloadUrl)
  }

  const theme = useTheme()

  const title: Variants = {
    hidden: {
      scale: 0,
      transition: {
        duration: 2
      }
    },
    visible: {
      scale: 1
    },
    hover: {
      scale: 1.2,
      rotateY: 20,
      rotateX: 20,
      border: `1px solid ${theme.palette.primary.main}`,
      transition: {
        duration: 0.3,
        velocity: 30
      }
    }
  }

  return (
    <Section ref={ref} id="inicio">
      <Container maxWidth="xs" style={{ marginBottom: 32 }}>
        <Typography
          whileHover="hover"
          initial="hidden"
          animate="visible"
          variants={title}
          variant="h1"
          color="secondary"
        >
          Olá, sou Gustavo Santos!
        </Typography>
      </Container>
      <Typography
        variant="h4"
        color="secondary"
        initial={{ width: 0 }}
        animate={{
          width: '100%',
          transition: {
            duration: 4
          }
        }}
        style={{
          marginBottom: 32,
          textAlign: 'center',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}
      >
        FullStack Developer
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        style={{ marginBottom: 32 }}
        onClick={handleDownload}
      >
        Download CV
      </Button>
      <motion.div
        animate={{ y: -10 }}
        transition={{
          repeat: Infinity,
          ease: 'easeInOut',
          repeatType: 'reverse',
          duration: 1
        }}
        initial={{ y: 10 }}
      >
        <ArrowDownwardIcon fontSize="large" />
      </motion.div>
    </Section>
  )
}
