import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Timeline } from '@material-ui/lab'
import { TextoLinhaTempo } from '../../utils/types'
import { LinhaTempo } from './LinhaTempo/LinhaTempo'
import { setNavBar } from '../../Hooks/SetNavbar'
import { Section } from '../common/Section'

export const Experiencia: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const textos: TextoLinhaTempo[] = [
    {
      esquerda: 'Voluntáriado',
      direita: 'Grupo de Apoio a Criança com Câncer'
    },
    { esquerda: 'InfoJr UFBA', direita: 'Empresa Júnior' },
    {
      esquerda: 'Klutch',
      direita:
        'Estágio: Aprendi muito de React, Typescript, Apolo GraphQL, Styled-Components e Next.js '
    },
    {
      direita: 'Dev Junior: Mexo bastante com Golang, Node Js e React!',
      esquerda: 'Facile'
    },
    {
      esquerda: 'O futuro',
      direita: "let's Go !"
    }
  ]

  const { ref } = setNavBar(4)

  return (
    <Section id="experiencia">
      <Grid
        ref={ref}
        container
        spacing={8}
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Grid item lg={12} xs={12}>
          <Typography variant="h2" color="secondary">
            Experiência
          </Typography>
        </Grid>
        <Grid style={{ height: '100%' }} item lg={12} xs={12}>
          <Timeline align="alternate">
            <LinhaTempo textos={textos} />
          </Timeline>
        </Grid>
      </Grid>
    </Section>
  )
}
