import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import LinearWithValueLabel from './BarraProgresso'
import { HabilidadeText } from '../../utils/types'

export const Habilidade: React.FC<HabilidadeText> = ({
  description,
  name,
  progress
}) => {
  return (
    <Grid item lg={12} xs={6}>
      <Typography variant="h6" style={{ textAlign: 'center' }}>
        {name.toUpperCase()}
      </Typography>
      <LinearWithValueLabel />
      <Typography variant="body1">{description}</Typography>
    </Grid>
  )
}
