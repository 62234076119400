import React from 'react'
import { ThemeProvider } from './ThemeProvider'
import { LanguageProvider } from './LanguageProvider'

const ContextsProviders: React.FC = ({ children }) => (
  <ThemeProvider>
    <LanguageProvider>{children}</LanguageProvider>
  </ThemeProvider>
)

export default ContextsProviders
