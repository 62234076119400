import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import LinearProgress, {
  LinearProgressProps
} from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'

export function LinearProgressWithLabel (
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
})

export default function LinearWithValueLabel () {
  const classes = useStyles()
  const [percent] = useState<number>(100)

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={percent} />
    </div>
  )
}
