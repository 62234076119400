import React from 'react'
import { useFormspark } from '@formspark/use-formspark'
import { useForm, Controller, SubmitErrorHandler } from 'react-hook-form'
import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { setNavBar } from '../../Hooks/SetNavbar'
import { Section } from '../common/Section'

interface Props {
  isMobile: boolean
}

export const Contato: React.FC<Props> = ({ isMobile }) => {
  const { enqueueSnackbar } = useSnackbar()

  const { ref } = setNavBar(5)

  const [submit, submitting] = useFormspark({
    formId: String(process.env.REACT_APP_FORM_ID)
  })

  interface ContactForm {
    nome: string
    email: string
    assunto: string
    mensagem: string
  }

  const { handleSubmit, control } = useForm<ContactForm>()

  const errorHandler: SubmitErrorHandler<ContactForm> = (errors) => {
    const messages = Object.values(errors).map((error) => error?.message)
    messages.forEach((message) => {
      enqueueSnackbar(message, { variant: 'error' })
    })
  }

  const onSubmit = async (data: ContactForm) => {
    try {
      await submit({ ...data })
      enqueueSnackbar('Mensagem enviada com sucesso!', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar('Não foi possivel enviar a mensagem', {
        variant: 'error'
      })
    }
  }

  return (
    <>
      <Section id="contato">
        <Grid
          container
          spacing={4}
          alignItems="center"
          style={{ height: '100%' }}
          justifyContent="space-evenly"
        >
          <Grid ref={ref} item lg={12} xs={12}>
            <Typography variant="h2" color="secondary">
              Contato
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={2}
            lg={6}
            md={8}
            xs={12}
            component="form"
            onSubmit={handleSubmit(onSubmit, errorHandler)}
          >
            <Grid item xs={6}>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Por favor preencher seu nome!'
                  }
                }}
                render={() => (
                  <TextField
                    fullWidth
                    defaultValue=""
                    variant="filled"
                    label="Nome"
                    type="name"
                    placeholder="Digite seu nome!"
                    id="name"
                  />
                )}
                name="nome"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Por favor preencher seu email!'
                  }
                }}
                render={() => (
                  <TextField
                    fullWidth
                    defaultValue=""
                    variant="filled"
                    label="Email"
                    type="email"
                    placeholder="Digite seu email!"
                    id="email"
                  />
                )}
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Por favor preencher o assunto do contato!'
                  }
                }}
                render={() => (
                  <TextField
                    fullWidth
                    defaultValue=""
                    variant="filled"
                    label="Assunto"
                    type="assunto"
                    placeholder="Digite seu assunto!"
                    id="assunto"
                  />
                )}
                name="assunto"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Por favor preencher a mensagem!'
                  }
                }}
                render={() => (
                  <TextField
                    fullWidth
                    defaultValue=""
                    variant="filled"
                    placeholder="Digite a sua mensagem :)"
                    rows={6}
                    multiline
                    label="Mensagem"
                    type="mensagem"
                    id="mensagem"
                  />
                )}
                name="mensagem"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                color="secondary"
                type="submit"
                variant="contained"
                disabled={submitting}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>

          <Grid item lg={6} md={4} xs={12}>
            <Typography variant="h4">Gustavo Santos</Typography>
            <Typography variant="h6">FullStack Dev</Typography>
            <Typography variant="h4">Email</Typography>
            <Typography variant="h6">gustavosisa@outlook.com</Typography>
          </Grid>
        </Grid>
      </Section>
    </>
  )
}
