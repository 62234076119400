import React, { useState } from 'react'
import { Collapse, Typography, TypographyProps } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

export interface DescricaoText {
  Descricao: { text: string; props?: TypographyProps }
  Title: { text: string; props?: TypographyProps }
}

interface Props extends DescricaoText {
  IsMobile: boolean
}

export const Descricao: React.FC<Props> = ({ Title, Descricao, IsMobile }) => {
  const [open, setOpen] = useState(IsMobile)

  return (
    <div style={{ marginTop: IsMobile ? 32 : 16 }}>
      <div onClick={() => setOpen(!open)} style={{ display: 'flex' }}>
        <Typography {...Title.props}>{Title.text}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </div>
      <Collapse in={open}>
        <Typography {...Descricao.props}>{Descricao.text}</Typography>
      </Collapse>
    </div>
  )
}
