import React from 'react'
import { Button } from '@material-ui/core'
import {
  Nav,
  Contato,
  Formacoes,
  Inicio,
  Sobre,
  Experiencia,
  Habilidades
} from '../Components/index'
import { useMobile } from '../Hooks/useMobile'
import MobileMenu from '../Components/Nav/MobileMenu'
import { NavProvider } from '../Hooks/NavProvider'
import { SnackbarProvider, SnackbarKey } from 'notistack'
import { Snap } from '../Components/common/Snap'

export const Home: React.FC = () => {
  const notistackRef = React.createRef<SnackbarProvider>()
  const onClickDismiss = (key: SnackbarKey) => () => {
    notistackRef.current && notistackRef.current.closeSnackbar(key)
  }

  const isMobile = useMobile()
  return (
    <>
      <NavProvider>
        <SnackbarProvider
          maxSnack={4}
          ref={notistackRef}
          style={{ fontWeight: 300 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          action={(key) => <Button onClick={onClickDismiss(key)}>X</Button>}
        >
          {isMobile ? <MobileMenu /> : <Nav />}
          <Snap maxWidth="lg">
            <Inicio />
            <Sobre isMobile={isMobile} />
            <Formacoes isMobile={isMobile} />
            <Habilidades isMobile={isMobile} />
            <Experiencia isMobile={isMobile} />
            <Contato isMobile={isMobile} />
          </Snap>
        </SnackbarProvider>
      </NavProvider>
    </>
  )
}
