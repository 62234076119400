import React from 'react'
import { CssBaseline, Paper } from '@material-ui/core'
import { Home } from './Page/Home'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ContextsProviders from './Hooks/Contexts'

const App: React.FC = () => {
  return (
    <ContextsProviders>
      <CssBaseline>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact>
              <Paper
                style={{
                  borderRadius: 0,
                  overflowX: 'hidden'
                }}
              >
                <Home />
              </Paper>
            </Route>
          </Switch>
        </BrowserRouter>
      </CssBaseline>
    </ContextsProviders>
  )
}

export default App
