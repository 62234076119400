import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { useUpdateNav } from './NavProvider'

export const setNavBar = (number: number) => {
  const { ref, inView } = useInView()
  const setNav = useUpdateNav()
  useEffect(() => {
    inView && setNav(number)
  }, [inView])

  return { ref }
}
