import { styled } from '@material-ui/core'

export const Section = styled('div')({
  width: '100%',
  height: '100vh',
  padding: '4%',
  scrollSnapAlign: 'center',
  display: 'grid',
  '& h2': {
    textAlign: 'center'
  },
  '& h1': {
    textAlign: 'center'
  },
  '&#inicio': {
    paddingTop: '10%',
    '@media(max-width:700px)': {
      paddingBottom: '15%',
      height: '100vh'
    }
  },
  '&#sobre': {
    padding: '8%',
    '@media(max-width:700px)': {
      padding: 0
    }
  },
  placeItems: 'center',
  '@media(max-width:700px)': {
    width: '100%',
    height: '100%',
    padding: 0
  }
})
