import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Formacao } from './Formacao'
import List from '../List/List'
import { setNavBar } from '../../Hooks/SetNavbar'
import { Section } from '../common/Section'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { database } from '../../firebase'
import { useSnackbar } from 'notistack'

interface FormacaoTexto {
  title: string
  tempo: string
  lugar: string
  escola: string
  descricao: string
  curso: string
}

export const Formacoes: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const { ref } = setNavBar(2)

  const { enqueueSnackbar } = useSnackbar()

  const [data, loading, error] = useCollectionDataOnce<FormacaoTexto>(
    database.collection('/formacoes')
  )

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Não foi possível carregar as formações', {
        variant: 'error'
      })
    }
  }, [error])

  return (
    <>
      <Section id="formacoes">
        <Grid container spacing={4} alignItems="center">
          <Grid item lg={12} xs={12}>
            <Typography
              ref={ref}
              variant="h2"
              color="secondary"
              style={{
                textAlign: 'center',
                marginTop: isMobile ? 48 : 16,
                lineHeight: 0.5
              }}
            >
              Formações
            </Typography>
          </Grid>
          {!loading && (
            <List<FormacaoTexto, {}>
              array={data ?? []}
              keyProp="title"
              Component={Formacao}
            ></List>
          )}
        </Grid>
      </Section>
    </>
  )
}
