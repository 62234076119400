import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { HabilidadeText } from '../../utils/types'
import { setNavBar } from '../../Hooks/SetNavbar'
import { database } from '../../firebase'
import { Section } from '../common/Section'
import { Item } from './Item'
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { useSnackbar } from 'notistack'

export const Habilidades: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [devOpsOpen, setDevOpsOpen] = useState(false)
  const [backEndOpen, setBackEndOpen] = useState(false)
  const [frontEndOpen, setFrontEndOpen] = useState(false)

  const [frontend, setFrontend] = useState<HabilidadeText[]>([])
  const [backend, setBackend] = useState<HabilidadeText[]>([])
  const [devops, setDevops] = useState<HabilidadeText[]>([])

  const [data, loading, error] = useCollectionDataOnce<HabilidadeText>(
    database.collection('/habilidades')
  )

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Não foi possível carregar as habilidades', {
        variant: 'error'
      })
    }
  }, [error])

  useEffect(() => {
    if (data) {
      setBackend(data.filter((data) => data.type === 'backend'))
      setFrontend(data.filter((data) => data.type === 'frontend'))
      setDevops(data.filter((data) => data.type === 'devops'))
    }
  }, [data, loading])

  const { ref } = setNavBar(3)

  return (
    <>
      <Section id="habilidades">
        <Grid
          container
          spacing={6}
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Grid item lg={12} xs={12}>
            <Typography
              variant="h2"
              style={{
                marginBottom: '3%',
                marginTop: isMobile ? 48 : 16,
                lineHeight: 0.5
              }}
              color="secondary"
            >
              Habilidades
            </Typography>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Item
              array={frontend ?? []}
              title="FRONT-END"
              open={frontEndOpen}
              setOpen={setFrontEndOpen}
            />
          </Grid>
          <Grid ref={ref} item lg={4} xs={12}>
            <Item
              array={backend ?? []}
              title="BACK-END"
              open={backEndOpen}
              setOpen={setBackEndOpen}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <Item
              array={devops ?? []}
              title="DEVOPS"
              open={devOpsOpen}
              setOpen={setDevOpsOpen}
            />
          </Grid>
        </Grid>
      </Section>
    </>
  )
}
