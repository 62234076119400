import { styled, Theme } from '@material-ui/core'

export const CollapsibleTitle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 16,
  cursor: 'pointer'
})

export const Container = styled('div')(
  ({ open, theme }: { theme: Theme; open: boolean }) => ({
    maxHeight: '50vh',
    '@media(min-width:1300px)': {
      maxHeight: '60vh',
      minHeight: '60vh'
    },
    width: '100%',
    overflowX: 'hidden',
    overflowY: open ? 'scroll' : 'hidden',
    '&::-webkit-scrollbar': {
      width: '0.2em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      maxHeight: 3
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      background: theme.palette.secondary.light,
      height: 3,
      maxHeight: 3
    }
  })
)
