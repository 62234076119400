import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Personal } from './Personal'
import { Descricao, DescricaoText } from './Descricao'
import { setNavBar } from '../../Hooks/SetNavbar'
import { Section } from '../common/Section'

export const Sobre: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const Text: DescricaoText[] = [
    {
      Title: { text: 'Dev', props: { variant: 'h5' } },
      Descricao: {
        text: `Ti em geral, começei a mexer desde criança
         e foram inumeras vezes que tive de levar o
          computador pra formatar pois eu conseguia 
          corromper o windows, logo aprendir a fazer 
          eu mesmo e assim começou essa relação
           com o mundo tech!`,
        props: { variant: 'body1' }
      }
    },
    {
      Title: { text: 'Musica', props: { variant: 'h5' } },
      Descricao: {
        text: `Música, foi meu escape do stress do 3° 
        ano e um dos meios que eu utilizo para manter
        a criatividade em dia! Sou cheio dos instrumentos
        de guitarra a cavaco e o gosto musical tambem é 
        por ai rsrsrs.`,
        props: { variant: 'body1' }
      }
    },
    {
      Title: { text: 'Carros', props: { variant: 'h5' } },
      Descricao: {
        text: 'Gosto bastante do Automobilismo, domingo é dia de acordar cedo pra ver a Formula 1 ! Além disso estou restaurando um Honda Civic 95 : )',
        props: { variant: 'body1' }
      }
    },
    {
      Title: { text: 'Resumo Profissional', props: { variant: 'h5' } },
      Descricao: {
        text: `Eu sou muito determinado e me esforço ao máximo a fazer as
        minhas determinações e mais, disposto a ajudar e trabalhar em
        equipe, a procura de aprender mais nesse mundo de
        desenvolvimento, Gosto bastante também da área de design de
        Devops. Possuo formação em inglês com diploma da universidade de
        Cambridge no nivel FCE B2.`,
        props: { variant: 'body1' }
      }
    },
    {
      Title: { text: 'Objetivos', props: { variant: 'h5' } },
      Descricao: {
        text: 'Fazer parte do desenvolvimento de sistemas que façam diferença para pessoas e que eu me identifique!.',
        props: { variant: 'body1' }
      }
    }
  ]

  const { ref } = setNavBar(1)

  return (
    <>
      <Section id="sobre">
        <Grid
          container
          spacing={4}
          alignItems="center"
          direction={!isMobile ? 'row' : 'column'}
          justifyContent="space-evenly"
        >
          <Grid item lg={12} xs={12}>
            <Typography variant="h2" color="secondary">
              Sobre mim
            </Typography>
          </Grid>
          {isMobile && (
            <Grid
              container
              item
              lg={6}
              md={6}
              xs={12}
              ref={ref}
              spacing={2}
              justifyContent="center"
              alignContent="center"
            >
              <Personal isMobile={isMobile} />
            </Grid>
          )}
          <Grid item lg={6} md={6} xs={12}>
            <Typography variant="h3">Paixões</Typography>
            <Grid item xs={12} style={{ marginTop: isMobile ? 48 : 32 }}>
              {Text.map((text) => (
                <Descricao
                  key={text.Title.text}
                  IsMobile={isMobile}
                  {...text}
                />
              ))}
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid
              container
              item
              lg={6}
              md={6}
              xs={12}
              ref={ref}
              spacing={2}
              justifyContent="center"
              alignContent="center"
            >
              <Personal isMobile={isMobile} />
            </Grid>
          )}
        </Grid>
      </Section>
    </>
  )
}
