import { Typography, Collapse, Grid } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { HabilidadeText } from '../../utils/types'
import { Habilidade } from './Habilidade'
import React from 'react'
import List from '../List/List'
import { CollapsibleTitle, Container } from './styles'

interface Props {
  open: boolean
  title: string
  setOpen(value: boolean): void
  array: HabilidadeText[]
}

export const Item = ({ open, title, setOpen, array }: Props) => {
  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <Container open={open}>
      <CollapsibleTitle onClick={handleClick}>
        <Typography variant="h4">{title}</Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </CollapsibleTitle>
      <Collapse style={{ padding: '0px 20px' }} in={open}>
        <Grid container spacing={2}>
          <List<HabilidadeText, {}>
            Component={Habilidade}
            array={array}
            keyProp="name"
          ></List>
        </Grid>
      </Collapse>
    </Container>
  )
}
