/* eslint-disable indent */
import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'

export const getTheme = (dark: boolean) =>
  createTheme({
    typography: {
      allVariants: { fontFamily: "'Space Grotesk', sans-serif" },
      h1: {
        fontFamily: "'Staatliches', cursive",
        fontDisplay: 'block'
      },
      body2: {
        fontSize: '1rem',
        fontWeight: 'bold'
      },
      button: {
        fontSize: '1rem',
        fontWeight: 'bold'
      },
      h5: {
        fontWeight: 'bold'
      }
    },
    palette: dark ? darkPalette : lightPalette,
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            'scrollbar-width': 'thin'
          },
          '& .wf-loading': {
            fontFamily: 'Helvetica Neue'
          },
          '*::-webkit-scrollbar': {
            width: '0.2em'
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            zIndex: 999,
            maxHeight: 3
          },
          '*::-webkit-scrollbar-thumb': {
            borderRadius: 5,
            background: 'grey',
            height: 3,
            maxHeight: 3
          }
        }
      }
    }
  })

const darkPalette: PaletteOptions = {
  type: 'dark',
  primary: { main: '#06D6A0' },
  secondary: { main: '#FFFBFF' },
  background: { default: '#00272B ', paper: '#00272B ' }
}

const lightPalette: PaletteOptions = {
  primary: { main: '#00272B ' },
  secondary: { main: '#00272B' },
  background: { default: '#FFFBFF ', paper: '#FFFBFF ' }
}

export const Theming: React.FC<{ darkTheme: boolean }> = ({
  children,
  darkTheme
}) => {
  const theme = React.useMemo(() => getTheme(darkTheme), [darkTheme])
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
